import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import { PlusIcon, PaperClipIcon } from '@heroicons/react/solid'
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Loadable from '../../../data/loadable';
import Heading from '../../../layout/admin/heading';
import { getCenterAdminToken } from '../../../../utils/auth';
import { getApiRequest, apiUrl } from '../../../../utils/request';
import { formatDay, capFirstLetter, isBrowser } from '../../../../utils/helpers';
import Badge from '../../../badges/badge';
import AdminTable from '../admin/common/admin-table';
import SEO from '../../../layout/seo';

function DownloadableAssets() {

  const assetsUrl = apiUrl.replace('com/api', 'com/assets');

  const assets = [
    {
      filename: `4x6-postcard.pdf`,
      name: '4x6 Postcard',
    },
    {
      filename: `flyer-imprintable.pdf`,
      name: 'Flyer (Imprintable)',
    },
    {
      filename: `host-center-how-to-document.pdf`,
      name: 'Host Center How To',
    },
    {
      filename: `league-rules.pdf`,
      name: 'League Rules',
    },
    {
      filename: 'fun-rollin-t-shirt-order-form.pdf',
      name: 'Fun Rollin\' T Shirt Order Form',
    },
  ];

  const rumAssets = [
    {
      filename: `rum-bucket-logo.jpeg`,
      name: 'Rum Bucket Logo',
    },
    {
      filename: `rum-bucket-league-rules.pdf`,
      name: 'Rum Bucket League Rules',
    },
    {
      filename: `fun-bowling-leagues-female.mp3`,
      name: 'Audio (Female)',
    },
    {
      filename: `fun-bowling-leagues-male.mp3`,
      name: 'Audio (Male)',
    },
    {
      href: 'https://www.dropbox.com/s/gt7mba681e94xqr/Rum%20Bucket%2060%20Approval%20Cut.mp4?dl=0',
      name: 'Rum Bucket Video',
    }
  ];

  return (
    <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6 max-w-3xl mx-0 md:mx-8 my-8">
      <h2 className="text-lg font-medium mb-4">Downloadable Assets</h2>
      <dt className="text-sm font-medium text-gray-600">Fun Rollin' Family Fun Leagues</dt>
      <dd className="mt-3 text-sm text-gray-900">
        <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
          {assets.map(asset => (
            <li key={asset.filename || asset.href} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="ml-2 flex-1 w-0 truncate">{asset.name}</span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href={asset.href || `${assetsUrl}/${asset.filename}`} target="_blank" rel="nofollow noopen" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Download
                </a>
              </div>
            </li>
          ))}
        </ul>
      </dd>

      <dt className="mt-4 text-sm font-medium text-gray-600">Rum Bucket Leagues</dt>
      <dd className="mt-3 text-sm text-gray-900">
        <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
          {rumAssets.map(asset => (
            <li key={asset.filename || asset.href} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="ml-2 flex-1 w-0 truncate">{asset.name}</span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href={asset.href || `${assetsUrl}/${asset.filename}`} target="_blank" rel="nofollow noopen" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Download
                </a>
              </div>
            </li>
          ))}
        </ul>
      </dd>
    </div>
  )
}

// Empty state for pending league products

function LeagueEmptyState() {
  return (

    <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6 max-w-3xl mx-0 md:mx-8 my-8">
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No leagues</h3>
        <p className="mt-1 text-sm text-gray-500">Get started by creating a new league product.</p>
        <div className="mt-6">
          <Link
            to="/app/center-admin/products/choose-league"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New League Product
          </Link>
        </div>
      </div>
    </div>
  );
}

function formatProductRow(item) {
  const {id, price, min_age: minAge, start_date: startDate, status, name, league, total_stock: total, purchased } = item;
  const leaguesWithScoring = [
    'rum-bucket',
    'newleague',
  ];
  return {
    name: <span><span className="font-bold">{name}</span><br /><span className="font-bold">League:</span>{league.name}</span>,
    hasScoring: leaguesWithScoring.indexOf(league.slug) !== -1,
    start_date: formatDay(startDate, 'M/D/Y'),
    stock: <span>Total: <span className="font-bold">{total}</span><br />Purchased: <span className="font-bold">{purchased}</span></span>,
    price: <NumberFormat value={price} displayType="text" thousandSeparator prefix="$" />,
    min_age: minAge,
    status: status === 'active' ? <Badge text={capFirstLetter(status)} color="green" /> : <Badge text={capFirstLetter(status)} color="yellow" />,
    id,
  };
}

const CenterAdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(null);
  const [active, setActive] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();

  // For checking for our PREVIEW value!
  const query = queryString.parse(isBrowser ? window.location.search : '');

  useEffect(() => {
    setLoading(true);
    setBreadcrumbs([{
      href: '/app/center-admin/dashboard',
      label: 'Dashboard',
    }]);

    const init = async () => {
      try {
        const result = await getApiRequest(`/center-admin/products?password=${getCenterAdminToken()}`);
        const { data } = result;
        const newPending = data.filter(product => product.status === 'pending');
        const newActive = data.filter(product => product.status === 'active');
        setPending(newPending);
        setActive(newActive);
      } catch (err) {
        console.log(err, 'error loading products');
      }
      setLoading(false);
    }
    
    init();
  }, []);

  const columns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'start_date',
      label: 'Start Date',
      isBold: true,
    },
    {
      key: 'stock',
      label: 'Inventory',
    },
    {
      key: 'price',
      label: 'Price',
    },
    {
      key: 'min_age',
      label: 'Min Age',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <span>

          <Link
            to={`/app/center-admin/product/view?id=${row.id}`}
            className="inline-flex items-center mr-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            View
          </Link>

          {/* <Link
            to={`/app/center-admin/product/edit?id=${row.id}`}
            className="inline-flex items-center mr-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit
          </Link> */}

          <Link
            to={`/app/center-admin/registrations?product_id=${row.id}`}
            className="inline-flex items-center mr-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Registrations
          </Link>
          {row.hasScoring && (
            <Link
              to={`/app/center-admin/scoring/scoreboard?product_id=${row.id}`}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Scoreboard
            </Link>
          )}

        </span>
      ),
    },
  ];

  // console.log(pending, active, 'pending and active leagues');

  const activeResults = {
    total: 0,
    from: 0,
    data: active,
  };
  const activeLinks = [
    {
      href: '/app/center-admin/products?status=active',
      secondary: true,
      label: 'View active',
    },
  ];

  const pendingResults = {
    total: 0,
    from: 0,
    data: pending,
  };
  const pendingLinks = [
    {
      href: '/app/center-admin/products?status=pending',
      secondary: true,
      label: 'View pending',
    },
  ];

  const hasActive = !!active && !!active.length;
  const hasPending = !!pending && !!pending.length;


  const actionLinks = [
    {
      label: 'Create Product',
      href: '/app/center-admin/products/choose-league',
    },
  ];


  return (
    <AdminContainer centerAdmin>
      <SEO title="Dashboard" />
      <Heading title="Dashboard" links={actionLinks} />
      <Loadable loading={loading}>
        {hasActive && (
          <div className="mb-8">
            <AdminTable sectionTitle="Active League Products" sectionLinks={activeLinks} results={activeResults} columns={columns} baseUrl="/app/center-admin/dashboard" rowFormatter={formatProductRow} />
          </div>
        )}
        {hasPending && (
          <div className="mb-8">
            <AdminTable sectionTitle="Pending League Products" sectionLinks={pendingLinks} results={pendingResults} columns={columns} baseUrl="/app/center-admin/dashboard" rowFormatter={formatProductRow} />
          </div>
        )}
        {!hasPending && !hasActive && (
          <LeagueEmptyState />
        )}
        <DownloadableAssets />
      </Loadable>
    </AdminContainer>
  );
}

export default CenterAdminDashboard;
